<template>
    <div>
      <p>版本号：0.0.4</p>
    </div>
</template>
  
  <script>
  export default {
    name: 'WorkPlace',
  }
  </script>
  
  <style  scoped>
  </style>  
  